@import url(https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --meraki-green: #67b346;
  --primary-color: #333;
  --secondary-color: #ddd;
  --disabled-color: #e0e0e0;
  --option-box-color: #f5f5f5;
  --background-color: #fff;
  --nav-background-color: #fff;
  --nav-border-color: #ddd;
  --navlinks-color: #a0a0a0;
  --home-icon-mr: url(/img/mr.svg);
  --home-icon-ms: url(/img/ms.svg);
  --home-icon-mx: url(/img/mx.svg);
  --home-icon-mi: url(/img/mi.svg);
  --home-icon-mv: url(/img/mv.svg);
  --home-icon-mt: url(/img/mt.svg);
}

:root.dark {
  --meraki-green: #67b346;
  --primary-color: #ebebeb;
  --secondary-color: #5a5a60;
  --disabled-color: #2b2b2d;
  --option-box-color: #3d4145;
  --background-color: #2e3335;
  --nav-background-color: #3b4143;
  --nav-border-color: var(--nav-background-color);
  --navlinks-color: #a0a0a0;
  --home-icon-mr: url(/img/mrDark.svg);
  --home-icon-ms: url(/img/msDark.svg);
  --home-icon-mx: url(/img/mxDark.svg);
  --home-icon-mi: url(/img/miDark.svg);
  --home-icon-mv: url(/img/mvDark.svg);
  --home-icon-mt: url(/img/mtDark.svg);
}

body {
  font-family: "Roboto";
  margin: 20px;
  font-size: 14px;
  text-align: center;
  color: #333;
  color: var(--primary-color);
  margin: 0;
  background: #fff;
  background: var(--background-color);
}

h1 {
  font-size: 30px;
  font-weight: 700;
  margin: 0 0 0 0;
}

h2 {
  font-weight: 500;
  font-size: 16px;
  margin: 35px 0 0 0;
}

h3 {
  font-weight: 300;
  margin: 0 0 0 0;
  font-size: 18px;
}

h4 {
  font-weight: 400;
  margin: 0 0 0 0;
  font-size: 12px;
}

h5 {
  font-weight: 300;
  margin: 10px 0 0 0;
  font-size: 12px;
}

.warning {
  color: red;
}

.disabled {
  pointer-events: none;
  opacity: 0.3;
}

.commaToNewLine {
  white-space: pre-wrap;
}

h6 {
  margin: 0;
  font-weight: 300;
  font-size: 18px;
}

a {
  color: #67b346;
  color: var(--meraki-green);
  text-decoration: none;
  cursor: pointer;
  font-weight: 300;
}

ol,
ul {
  padding-left: 30px;
}

p {
  margin: 3px 0 0 0;
}

label {
  display: flex;
  font-weight: 300;
}

.navBar {
  width: 100%;
  text-align: right;
  position: fixed;
  top: 0;
  left: 0;
  height: 75px;
  border-bottom: 1px solid #ddd;
  border-bottom: 1px solid var(--nav-border-color);
  background-color: #fff;
  background-color: var(--nav-background-color);
  z-index: 2;
}

.merakilogo {
  margin: 24px 0 0 30px;
  width: 110px;
  position: fixed;
  left: 0;
}

.navLinksContainer {
  right: 30px;
  top: 30px;
  position: fixed;
}

.navTitle {
  color: #333;
  color: var(--primary-color);
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
}

.navLinks {
  color: #a0a0a0;
  color: var(--navlinks-color);
  font-size: 15px;
  font-weight: 300;
  margin: 0 0 0 15px;
  padding: 2px;
}

.navLinks:hover {
  color: #67b346;
  color: var(--meraki-green);
}

.active {
  color: #67b346;
  color: var(--meraki-green);
  font-weight: 800;
}

.white {
  color: white;
}

.switchPicture.navPicture {
  width: 300px;
  margin: 50px 0;
  display: flex;
}

.homeForm {
  display: flex;
  justify-content: center;
  margin: 35vh 0 15vh;
}

.form {
  display: flex;
  justify-content: center;
  margin: 150px 0 75px 0;
}

.topSubHeader {
  font-weight: 400;
  margin: 0 0 5px 0;
  font-size: 18px;
}

.bottomSubHeader {
  font-weight: 300;
  margin: 5px 0 0 0;
  font-size: 16px;
}


.mainHomeTitle {
  margin: 0 0 50px 0;
}

.mainHomeTitle h1 {
  font-size: 40px;
}

.mainHomeTitle h3 {
  font-size: 25px;
}

.homeIconsContainerSplit {
  display: flex;
}

.homeIconsContainer {
  display: flex;
  flex-direction: column;
  margin: 5% 0;
}

.newHomeIconsContainer {
  display: flex;
  margin: 5% 0;
}

.homeIconsContainer2 {
  margin: 0 40px 40px;
  display: flex;
}

.homeIconsContainer2 a {
  color: #333;
  color: var(--primary-color);
}

.homeIconsContainer2:hover a {
  color: #67b346;
  color: var(--meraki-green);
}

.homeIcons:hover {
  cursor: pointer;
  color: #67b346;
  color: var(--meraki-green);
}

/* ------------------------------------------------------------------------------------------------------ Table formatting ------------------------------------------------------------------------------------------------------ */

.smart-retention-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 5px;
  margin-bottom: 5px;
}

.smart-retention-table th,
.smart-retention-table td {
  border: 1px solid #ddd;
  border: 1px solid var(--secondary-color);
  padding: 8px;
  text-align: left;
}

.smart-retention-table th {
  background-color: #ddd;
  background-color: var(--secondary-color);
}

/* ------------------------------------------------------------------------------------------------------ Home Icons ------------------------------------------------------------------------------------------------------ */
.homeIconsMR {
  background: url(/img/mr.svg);
  background: var(--home-icon-mr);
  width: 0px;
  height: 0px;
  padding: 20px;
  margin: 0 0px 10px;
}

.homeIconsMS {
  background: url(/img/ms.svg);
  background: var(--home-icon-ms);
  width: 0px;
  height: 0px;
  padding: 20px;
  margin: 0 0px 10px;
}

.homeIconsMX {
  background: url(/img/mx.svg);
  background: var(--home-icon-mx);
  width: 0px;
  height: 0px;
  padding: 20px;
  margin: 0 0px 10px;
}

.homeIconsMI {
  background: url(/img/mi.svg);
  background: var(--home-icon-mi);
  width: 0px;
  height: 0px;
  padding: 20px;
  margin: 0 0px 10px;
}

.homeIconsMV {
  background: url(/img/mv.svg);
  background: var(--home-icon-mv);
  width: 0px;
  height: 0px;
  padding: 20px;
  margin: 0 0px 10px;
}

.homeIconsMT {
  background: url(/img/mt.svg);
  background: var(--home-icon-mt);
  width: 0px;
  height: 0px;
  padding: 20px;
  margin: 0 0px 10px;
}

/* ------------------------------------------------------------------------------------------------------ Home Icons End------------------------------------------------------------------------------------------------------ */
.width100 {
  width: 100%;
  display: flex;
}

.leftForm {
  text-align: left;
  display: flex;
  margin: 0 20px 0 0;
  width: 400px;
  flex-direction: column;
}

.rightFormContainer {
  display: flex;
  justify-content: flex-end;
  width: 400px;
  margin: 0 0 0 20px;
}

.rightForm {
  text-align: left;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.datasheetContainer {
  display: flex;
  justify-content: end;
  padding: 5px 0 0 0;
}

.datasheet {
  padding: 0 5px 0 0;
}

.installation {
  padding: 0 0 0 5px;
}

.datasheetDivider {
  margin: 0;
}

.learnMoreAbout {
  display: flex;
  margin: 5px 0 0 0;
}

.labelDisabled {
  color: #ddd;
  color: var(--secondary-color);
}

/* Home screen icons */
.optionHome {
  border: 1px solid #ddd;
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
  display: flex;
  width: 200px;
  margin: 10px;
}

.h3Home {
  font-weight: 700;
    margin: 0 0 0 15px;
    font-size: 30px;
    padding: 8px 0 0 0;
  color: #333;
  color: var(--primary-color);
}

.pHome {
  color: white;
  padding: 0;
  margin: -3px 0 0 16px;
  font-size: 12px;
}

.homeIcons {
  height: 45px;
  margin: 0px 0;
  padding: 10px 0;
}

.homeIconsSensor {
  height: 56px;
  padding: 15px 15px 0px 0;
  margin: -4px;
  z-index: -1;
  position: relative;
}

.optionHome:hover {
  border: 1px solid #333;
  border: 1px solid var(--primary-color);
  cursor: pointer;
}

/* End Home Screen Icons */

.optionDisabled {
  border: 1px solid #ddd;
  border: 1px solid var(--secondary-color);
  padding: 25px;
  margin: 10px 0 0 0;
  border-radius: 5px;
  color: #ddd;
  color: var(--secondary-color);
  display: flex;
}

.optionClicked {
  border: 2px solid #67b346;
  border: 2px solid var(--meraki-green);
  padding: 25px;
  margin: 10px 0 0 0;
  border-radius: 5px;
  display: flex;
}

.option {
  padding: 25px;
  margin: 10px 0 0 0;
  border: 1px solid #ddd;
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
  display: flex;
}

.option:hover {
  border: 1px solid #333;
  border: 1px solid var(--primary-color);
  cursor: pointer;
}

.optionClicked:hover {
  cursor: pointer;
}

.secondaryOptionsContainer {
  background-color: #f5f5f5;
  background-color: var(--option-box-color);
  color: #333;
  color: var(--primary-color);
  border: 2px solid #f5f5f5;
  border: 2px solid var(--option-box-color);
  width: 90%;
  padding: 20px 5%;
  margin: 10px 0 10px 0;
  font-size: 15px;
  font-weight: 500;
}

.secondaryOptionsContainer2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.secondaryOption {
  padding: 4% 4%;
  margin: 10px 4px 0 0;
  border: 1px solid #ddd;
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
  width: 92%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.secondaryOptionClicked {
  border: 2px solid #67b346;
  border: 2px solid var(--meraki-green);
  padding: 4% 4%;
  margin: 10px 4px 0 0;
  border-radius: 5px;
  width: 92%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.secondaryOption:hover {
  border: 1px solid #333;
  border: 1px solid var(--primary-color);
  cursor: pointer;
}

.secondaryOptionClicked:hover {
  cursor: pointer;
}

.recommendedSFPModules {
  margin-top: 20px;
}

.sfpModuleInfo {
  font-weight: 300;
  font-size: 10px;
}

.shrug {
  width: 18px;
  padding: 0 5px 0 0;
}

.optionBubble {
  color: white;
  font-size: 12px;
  font-weight: 500;
  background-color: #67b346;
  background-color: var(--meraki-green);
  border: 1px solid #67b346;
  border: 1px solid var(--meraki-green);
  border-radius: 15px;
  padding: 7px 15px;
  text-align: center;
  display: inline-block;
  margin: 0 10px 10px 0;
}

.optionBubble:hover {
  cursor: pointer;
}

.switchPictureContainer {
  margin: 0 0 20px 0;
}

.leftSide {
  width: 50%;
  text-align: left;
}

.rightSide {
  width: 50%;
  text-align: right;
  color: #a0a0a0;
  color: var(--navlinks-color);
}

.secondaryLeftSide {
  width: 70%;
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  margin: 0 0 0 0;
  font-size: 12px;

}

.secondaryRightSide {
  width: 30%;
  text-align: right;
  font-weight: 300;
  margin: 0 0 0 0;
  font-size: 12px;
  color: #a0a0a0;
}

.switchHeader:hover {
  cursor: pointer;
}

.selectedSwitchHeader {
  font-weight: 800;
  color: #67b346;
  color: var(--meraki-green);
}

/*  ---------------------------------------------------  Model Icons --------------------------------------------------- */
.modelIconXXSmall {
  width: 14%;
  border: 1px solid #fff;
  border: 1px solid var(--background-color);
  border-radius: 5px;
}

.modelIconXSmall {
  width: 17.5%;
  border: 1px solid #fff;
  border: 1px solid var(--background-color);
  border-radius: 5px;
}

.modelIconSmall {
  width: 25%;
  border: 1px solid #fff;
  border: 1px solid var(--background-color);
  border-radius: 5px;
}

.modelIconMedium {
  width: 30%;
  border: 1px solid #fff;
  border: 1px solid var(--background-color);
  border-radius: 5px;
}

.modelIconLarge {
  width: 40%;
  border: 1px solid #fff;
  border: 1px solid var(--background-color);
  border-radius: 5px;
}

.modelIconHalf {
  width: 50%;
  border: 1px solid #fff;
  border: 1px solid var(--background-color);
  border-radius: 5px;
}

.modelIconFull {
  width: 100%;
  border: 1px solid #fff;
  border: 1px solid var(--background-color);
  border-radius: 5px;
}

.modelIcon:hover {
  border: 1px solid #333;
  border: 1px solid var(--primary-color);
  cursor: pointer;
  border-radius: 5px;
}

.switchInfo {
  font-weight: 300;
  font-size: 12px;
  margin: 5px 0;
}

.switchInfoSelected {
  background: #f5f5f5;
  background: var(--option-box-color);
  padding: 20px;
  margin: 6px 0 0 0;
}

.switchInfoHeader {
  font-size: 12px;
  font-weight: 500;
  display: inline;
}

.margin10 {
  margin: 10px 0 0 0;
  display: flex;
}

.details {
  display: none;
}

.switchModelDetailContainer {
  display: inline;
}

.switchModelDetail {
  font-weight: 300;
  font-size: 11px;
  display: inline-block;
  padding: 4px 0;
  width: 112px;
  text-align: center;
  color: #333;
  color: var(--primary-color);
  border: 1px solid #ddd;
  border: 1px solid var(--secondary-color);
  border-radius: 3px;
  margin: 2px 4px 2px 2px;
}

.switchModelDetailSelected {
  font-weight: 500;
  font-size: 11px;
  display: inline-block;
  padding: 4px 0;
  width: 112px;
  text-align: center;
  color: #fff;
  color: var(--background-color);
  background: #67b346;
  background: var(--meraki-green);
  border: 1px solid #67b346;
  border: 1px solid var(--meraki-green);
  border-radius: 3px;
  margin: 2px 4px 2px 2px;
}

.antennaPicsContainerContainer {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
  margin: 5px 0;
}

.antennaPicsCircle {
  font-weight: 300;
  font-size: 12px;
  display: inline-block;
  padding: 5px 5px;
  width: 104px;
  height: 30px;
  text-align: center;
  color: #333;
  color: var(--primary-color);
  border: 1px solid #ddd;
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
  margin: 2px 4px 2px 0px;
  cursor: pointer;
}

.antennaPicsCircle:hover {
  border: 1px solid #333;
  border: 1px solid var(--primary-color);
}

.antennaPicsCircleSelected {
  font-weight: 500;
  font-size: 12px;
  display: inline-block;
  padding: 5px 5px;
  width: 99px;
  height: 30px;
  text-align: center;
  color: #333;
  color: var(--primary-color);
  border: 1px solid #ddd;
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
  margin: 2px 4px 2px 2px;
  cursor: pointer;
  background: #67b346;
  background: var(--meraki-green);
  color: #fff;
  color: var(--background-color);
  border: 1px solid #67b346;
  border: 1px solid var(--meraki-green);
}

.antennaSummary {
  margin: 10px 0;
}

.antennaPics.antennaPicsLarge {
  font-size: 15px;
}

.antennaPics {
  margin: 0;
}

.switchModelDetail:hover {
  cursor: pointer;
}

.switchModelDetailSelected:hover {
  cursor: pointer;
}

.orderingInformation {
  color: #67b346;
  color: var(--meraki-green);
}

.designedByContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px 0 50px 0;
}

.designedBy {
  font-weight: 300;
  display: inline;
  padding: 0 5px;
}

.designedBy2 {
  font-weight: 300;
  display: inline;
  color: #67b346;
  color: var(--meraki-green);
}

.designedBy3 {
  font-weight: 300;
  display: inline;
  padding: 0 5px;
}

.greenheart2 {
  width: 14px;
  display: none;
}

.greenheart {
  width: 14px;
}

.changeLogContainer:hover {
  cursor: pointer;
}

.changeLog {
  padding: 50px;
  background: #3b4143;
  color: white;
  font-family: "Monaco", "Courier Prime";
  font-size: 12px;
  text-align: left;
}

.makeAWishContainer {
  display: flex;
  justify-content: center;
}

.makeAWish {
  color: #333;
  color: var(--primary-color);
  font-size: 12px;
  background-color: #f5f5f5;
  background-color: var(--option-box-color);
  width: 80px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #f5f5f5;
  border: 1px solid var(--option-box-color);
}

.makeAWish:hover {
  border: 1px solid #333;
  border: 1px solid var(--primary-color);
  cursor: pointer;
}

.cloudPlaformIcon {
  width: 40px;
  margin: 10px 10px 0 0;
}

/* ---------------------------------------------------- wish ---------------------------------------------------- */

.wishContainer {
  display: flex;
  justify-content: center;
  padding: 50px 0;
  margin: 50px 0 0 0;
  border-top: 1px solid #ddd;
  border-top: 1px solid var(--secondary-color);
}

.group {
  position: relative;
  margin-bottom: 45px;
}

.wishForm {
  width: 40%;
}

.wishInput {
  padding: 10px 0 10px 5px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #ddd;
  border-bottom: 1px solid var(--secondary-color);
  font-size: 15px;
  font-weight: 300;
  background: #fff;
  background: var(--background-color);
  color: #333;
  color: var(--primary-color);
}

.wishInput:focus {
  outline: none;
  background: #fff;
  background: var(--background-color);
}

.wishLabel {
  color: #333;
  color: var(--primary-color);
  font-size: 15px;
  font-weight: 300;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* active state */
.wishInput:focus~label,
.wishInput:valid~label {
  top: -20px;
  font-size: 14px;
  color: #333;
  color: var(--primary-color);
}

/* auto fill styles */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  border-bottom: 1px solid #ddd;
  border-bottom: 1px solid var(--secondary-color);
  -webkit-text-fill-color: #333;
  -webkit-text-fill-color: var(--primary-color);
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  -webkit-box-shadow: 0 0 0px 1000px var(--background-color) inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  caret-color: #333;
  caret-color: var(--primary-color);
}

/* ---------------------------------------------------- mx input option styles  ----------------------------------------------------*/

.optionForm {
  width: 100%;
}

/* option text inputs */
.optionGroup {
  position: relative;
  width: 100%;
  display: flex;
  z-index: 0;
}

input.optionInput {
  background: #fff;
  background: var(--background-color);
  color: #333;
  color: var(--primary-color);
}

.optionInput {
  padding: 25px 25px 25px 25px;
  width: 100%;
  margin: 10px 0 0 0;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #ddd;
  border: 1px solid var(--secondary-color);
}

.optionInput:focus {
  outline: none;
}

.optionInputClicked {
  padding: 25px 25px 25px 25px;
  border: 2px solid #67b346;
  border: 2px solid var(--meraki-green);
  width: 100%;
  margin: 10px 0 0 0;
  widht: 100px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
  background: #fff;
  background: var(--background-color);
  color: #333;
  color: var(--primary-color);
}

.optionInput:hover {
  border: 1px solid #333;
  border: 1px solid var(--primary-color);
  cursor: pointer;
}

.optionLabel {
  color: #333;
  color: var(--primary-color);
  font-size: 15px;
  font-weight: 400;
  position: absolute;
  pointer-events: none;
  left: 25px;
  top: 35px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* active state */
.optionInput:focus~label,
.optionInputClicked:valid~label {
  top: 20px;
  font-size: 12px;
  color: #333;
  color: var(--primary-color);
  font-weight: 300;
}

.invisible {
  display: none;
}

/* ----------------------------------------------------  Toggle ---------------------------------------------------- */
.react-toggle {
  margin: 0px 0px -3px 12px;
  touch-action: pan-x;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 28px;
  height: 15px;
  padding: 0;
  border: solid 1px #a0a0a0;
  border: solid 1px var(--navlinks-color);
  border-radius: 30px;
  background-color: white;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  /* background-color: #000000; */
}

.react-toggle--checked .react-toggle-track {
  background-color: #333;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  /* background-color: #128D15; */
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border: 1px solid #a0a0a0;
  border: 1px solid var(--navlinks-color);
  border-radius: 50%;
  background-color: #fafafa;
  box-sizing: border-box;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 14px;
  border-color: #333;
}

.react-toggle--focus .react-toggle-thumb {
  /* -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
  -moz-box-shadow: 0px 0px 3px 2px #0099E0;
  box-shadow: 0px 0px 2px 3px #0099E0; */
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  /* -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
  -moz-box-shadow: 0px 0px 5px 5px #0099E0;
  box-shadow: 0px 0px 5px 5px #0099E0; */
}

/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@      MEDIUM    @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */
@media only screen and (max-width: 1000px) {
  .form {
    flex-direction: column-reverse;
    align-items: center;
    margin: 120px 0 0px 0;
  }

  .leftForm {
    border-top: 1px solid #ddd;
    border-top: 1px solid var(--secondary-color);
    margin: 50px 0 0 0;
    padding: 50px 0;
    width: 500px;
  }

  .rightFormContainer {
    margin: 0;
    width: 500px;
  }

  .homeIconsContainer {
    display: flex;
    margin: 5% 0;
    flex-direction: column;
  }

  .wishForm {
    width: 500px;
  }

  .switchModelDetail {
    font-weight: 300;
    font-size: 12px;
    display: inline-block;
    padding: 4px 0;
    width: 145px;
    text-align: center;
    color: #333;
    color: var(--primary-color);
    border: 1px solid #ddd;
    border: 1px solid var(--secondary-color);
    border-radius: 3px;
    margin: 2px 4px 2px 2px;
  }

  .switchModelDetailSelected {
    font-weight: 500;
    font-size: 12px;
    display: inline-block;
    padding: 4px 0;
    width: 145px;
    text-align: center;
    color: #fff;
    color: var(--background-color);
    background: #67b346;
    background: var(--meraki-green);
    border: 1px solid #67b346;
    border: 1px solid var(--meraki-green);
    border-radius: 3px;
    margin: 2px 4px 2px 2px;
  }
}

/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@      MOBILE    @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */
@media only screen and (max-width: 750px) {
  .form {
    flex-direction: column-reverse;
    align-items: center;
    margin: 120px 0 0px 0;
  }

  .mainHomeTitle {
    margin-bottom: 0;
  }

  .mainHomeTitle h1 {
    font-size: 30px;
  }

  .mainHomeTitle h3 {
    font-size: 18px;
  }

  .navBar {
    width: 100%;
    text-align: right;
    position: fixed;
    top: 0;
    left: 0;
    height: 60px;
    z-index: 2;
  }

  .merakilogo {
    margin: 20px 0 0 20px;
    width: 80px;
    position: fixed;
    left: 0;
  }

  .navLinks {
    color: #a0a0a0;
    color: var(--navlinks-color);
    font-size: 12px;
    font-weight: 300;
    margin: 0 0 0 10px;
    padding: 2px;
  }

  .react-toggle {
    margin: 0 0 -4px 12px;
  }

  .active {
    color: #67b346;
    color: var(--meraki-green);
    font-weight: 800;
  }

  .navLinksContainer {
    right: 30px;
    top: 23px;
    position: fixed;
  }

  .homeForm {
    margin: 125px 0 0px;
  }

  .homeIconsContainerSplit {
    display: flex;
    margin: auto;
    flex-direction: column;
  }

  .homeIconsContainer {
    display: flex;
    margin: 35px auto;
    flex-direction: column;
    width: 100%;
  }

  .homeIconsContainer2 {
    margin: 20px;
    display: flex;
    flex-direction: column;
  }

  .switchModelDetail {
    font-weight: 300;
    font-size: 12px;
    display: inline-block;
    padding: 4px 0;
    width: 130px;
    text-align: center;
    color: #333;
    color: var(--primary-color);
    border: 1px solid #ddd;
    border: 1px solid var(--secondary-color);
    border-radius: 3px;
    margin: 2px 4px 2px 2px;
  }

  .switchModelDetailSelected {
    font-weight: 500;
    font-size: 12px;
    display: inline-block;
    padding: 4px 0;
    width: 130px;
    text-align: center;
    color: #fff;
    color: var(--background-color);
    background: #67b346;
    background: var(--meraki-green);
    border: 1px solid #67b346;
    border: 1px solid var(--meraki-green);
    border-radius: 3px;
    margin: 2px 4px 2px 2px;
  }
  
  .optionHome {
    border: 1px solid #ddd;
    border: 1px solid var(--secondary-color);
    border-radius: 5px;
    display: flex;
    width: 270px;
    margin: 5px;
  }

  .h3Home {
    font-weight: bold;
    margin: 10px 0 0 20px;
    font-size: 25px;
    padding: 0;
    color: #333;
    color: var(--primary-color);
  }

  .pHome {
    color: white;
    padding: 0;
    margin: -3px 0 10px 21px;
    font-size: 12px;
  }

  .homeIcons {
    height: 35px;
    margin: 0;
    padding: 12px 0;
  }
  .homeIconsSensor {
    height: 54px;
    padding: 14px 10px 0px 0;
    margin: -4px;
    z-index: -1;
    position: relative;
}

  .homeNavText {
    font-size: 15px;
  }

  .leftForm {
    border-top: 1px solid #ddd;
    border-top: 1px solid var(--secondary-color);
    margin: 50px 0 0 0;
    padding: 50px 0;
    width: 80%;
  }

  .rightFormContainer {
    margin: 0;
    width: 80%;
  }

  .makeAWishContainer {
    margin: 20px 0 0 0;
  }

  .wishForm {
    width: 80%;
  }

  .designedByContainer {
    flex-direction: column;
  }

  .greenheart2 {
    width: 14px;
    display: inline;
  }

  .greenheart {
    display: none;
  }

  .designedBy3 {
    display: none;
  }

  .designedBy {
    margin: 5px;
  }
}
